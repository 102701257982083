<template>
    <div id="Pgwrp" ref="Pgwrp">
      <div>
        <div class="subtabs" style="height: 2%; background: none">
          <input type="radio" name="subtabs" id="AcademicsTab" checked />
          <label for="AcademicsTab">Staff Leave Request</label>
          <div class="tab">
         
            <div class="form-row">
              <div class="form-group col-md-12 mt-3">
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">Term</div> -->
                      <div class="cell" style="width: 15%;padding-left: 50px;">Staff Name</div>
                      <div class="cell" style="padding-right: 10px;">From </div>
                      <div class="cell" style="width: 10%;">To</div>
                      <div class="cell" style="width: 15%;">No Of Days</div>
                      <div class="cell" style="width: 15%;">Applied Date</div>
                      <div class="cell">Status</div>

                      <div class="cell">Action</div>
                    </div>
                    <div
                  class="resrow"
                  v-for="(item, index) in staffApplyLeaveRequest"
                  :key="index"
                >
                  <div class="cell">{{ item.staffDetail ? item.staffDetail.firstName : '' }}</div>
                 
                  <div class="cell">{{ item.from | dateFormat }}</div>
                  <div class="cell">{{ item.to | dateFormat }}</div>
                  <div class="cell">{{ item.noOfDates.length }}</div>
                  
                  <div class="cell">{{ item.createdDate }}</div>
                  <div class="cell" :style="item.status == 'Pending Approval' ? 'color: darkorange;' : '' ">{{ item.status || '' }}</div>
                  <div class="cell">
                    <button
                  class="btn round orange tooltipt"
                  @click.prevent="openActionPopUp(item,index)"
                >
                  <i class="far fa-eye"></i
                  ><span class="tooltiptext">View</span>
                </button>
                    
                  </div>
                </div>
                  
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
  
          <input type="radio" name="subtabs" id="CurricularActivities" />
          <label for="CurricularActivities">Student Leave Request</label>
          <div class="tab">
            <div class="form-row">
              <div class="form-group col-md-12 mt-3">
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">Term</div> -->
                      <div class="cell" style="width: 24%">Student Name</div>
                      <div class="cell">Class</div>
                      <div class="cell">Classroom</div>
                      <div class="cell">From </div>
                      <div class="cell">To</div>
                      <div class="cell">No Of Days</div>
                      <div class="cell">Applied Date</div>
                      <div class="cell">Status</div>
                      <div class="cell">Action</div>
                    </div>
                    <div
                  class="resrow"
                  v-for="(item, index) in studentApplyLeaveRequest"
                  :key="index"
                >
                  <div class="cell">{{ item.student ? item.student.firstName : '' }}</div>
                  <div class="cell">{{ item.class ? item.class.className : '' }}</div>
                  <div class="cell">{{ item.section ? item.section.name : '' }}</div>

                  <div class="cell">{{ item.from | dateFormat }}</div>
                  <div class="cell">{{ item.to | dateFormat }}</div>
                  <div class="cell">{{ noOfDaysLeave(item.noOfDates) }}</div>
                  
                  <div class="cell">{{ item.createdDate | dateFormat }}</div>
                  <div class="cell" :style="item.status == 'Pending Approval' ? 'color: darkorange;' : '' ">{{ item.status || '' }}</div>
                  <div class="cell">
                    <button
                  class="btn round orange tooltipt"
                  @click.prevent="openStudentActionPopUp(item,index)"
                >
                  <i class="far fa-eye"></i
                  ><span class="tooltiptext">View</span>
                </button>
                    
                  </div>
                </div>
                  
                  </div>
                  
                </div>
              </div>
                </div>
          </div>
        </div>
      </div>
      <div>
      <b-modal
        id="viewActionPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
      <button type="button" class="close"  @click.prevent="closeActionPopUp">×</button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Leave Applied</h2>
          <br />
          <div>
            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="date"
                      disabled
                      v-model="staffLeaveDetails.from"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >From Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="date"
                      disabled
                      v-model="staffLeaveDetails.to"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >To Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    {{  staffLeaveDetails.noOfDates ? staffLeaveDetails.noOfDates.length : ''  }}
                   
                    <label for="deptid"
                      >No Of Days </label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <textarea
                            class="form-control web-form-input"
                            style="min-height:135px;resize:none"
                            id="message"
                            disabled
                            v-model="staffLeaveDetails.notes"
                          ></textarea>
                  
                    <label for="deptid"
                      >Reason </label
                    >
                  </span>
                </div>
              </div>

              <div class="widjetfooter ">
                <div class="text-center dk_iconsml">
                  <button type="button"  class="btn btncl btn-secondary clsmdl" style="background-color: green;width: 130px;" @click.prevent="leaveAction(staffLeaveDetails,'Approved')">
                      <span>Approve</span>
                  </button>
                  <button type="button" class="btn btncl btn-secondary clsmdl" style="background-color: red" id="clbtn" @click.prevent="leaveAction(staffLeaveDetails,'Rejected')">
                       <span>Reject</span>
                  </button>
                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click.prevent="closeActionPopUp">
                       <span>Close</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
         
        </div>
      </b-modal>
      <b-modal
        id="viewStudentActionPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
      <button type="button" class="close"  @click.prevent="closeStudentActionPopUp">×</button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Leave Applied</h2>
          <br />
          <div>
            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                  <span class="has-float-label" v-if="studentLeaveDetails.student">
                    <input
                      type="text"
                      disabled
                      v-model="studentLeaveDetails.student.firstName"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >Student Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                  <span class="has-float-label" v-if="studentLeaveDetails.class">
                    <input
                      type="text"
                      disabled
                      v-model="studentLeaveDetails.class.className"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >Class <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                  <span class="has-float-label" v-if="studentLeaveDetails.section">
                    <input
                      type="text"
                      disabled
                      v-model="studentLeaveDetails.section.name"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >Classroom <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                  Is it half day leave?
                  <div class=" chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input type="checkbox"  disabled v-model="studentLeaveDetails.isHalfDay" />
                    <span class="checkmarkchk" style="left: 71px !important;"></span>
                    
                  </label>
                </div>
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="date"
                      disabled
                      v-model="studentLeaveDetails.from"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >From Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      disabled
                      v-model="studentLeaveDetails.fromDayType"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >From Day Type <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3" v-if="!studentLeaveDetails.isHalfDay">
                  <span class="has-float-label">
                    <input
                      type="date"
                      disabled
                      v-model="studentLeaveDetails.to"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >To Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-2 mt-3 pr-md-3" v-if="!studentLeaveDetails.isHalfDay">
                  <span class="has-float-label">
                    <input
                      disabled
                      v-model="studentLeaveDetails.toDayType"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >To Day Type <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                  <span class="has-float-label">
                    {{  noOfDaysLeave(studentLeaveDetails.noOfDates)  }}
                   
                    <label for="deptid"
                      >No Of Days </label
                    >
                  </span>
                </div>
               
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <textarea
                            class="form-control web-form-input"
                            style="min-height:135px;resize:none"
                            id="message"
                            disabled
                            v-model="studentLeaveDetails.notes"
                          ></textarea>
                  
                    <label for="deptid"
                      >Reason </label
                    >
                  </span>
                </div>
              </div>

              <div class="widjetfooter">
                <div class="text-center dk_iconsml">
                  <button type="button"  class="btn btncl btn-secondary clsmdl" style="background-color: green;width: 130px;" @click.prevent="studentLeaveAction(studentLeaveDetails,'Approved')">
                      <span>Approve</span>
                  </button>
                  <button type="button" class="btn btncl btn-secondary clsmdl" style="background-color: red" id="clbtn" @click.prevent="studentLeaveAction(studentLeaveDetails,'Rejected')">
                       <span>Reject</span>
                  </button>
                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click.prevent="closeStudentActionPopUp">
                       <span>Close</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
         
        </div>
      </b-modal>
    </div>
      
    </div>
  </template>
  <script>
  import ViSpinner from "../Common/ViSpinner";
  import ViService from "@/services/ViService";
  import secureUI from "../../utils/secureUI";
  import "vue-select/dist/vue-select.css";
  import Select from "vue-select";
  
  export default {
    name: "teacher",
  
    data() {
      return {
        staffApplyLeaveRequest: [],
        staffLeaveDetails: {},
        studentApplyLeaveRequest: [],
        studentLeaveDetails: {}
      };
    },
    created() {
      this.getStaffLeaveApplyList();
      this.getStudentLeaveApplyList()
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
    },
    methods: {
      noOfDaysLeave(data){
        if(data && data.length > 0){
        let totalDays = data.map((o) => Number(o.noDays))
              .reduce((a, c) => {
                return a + c;
              });

              if(totalDays){
                return  totalDays

              }else{
                return  '-'

              }
            }
      },
    async getStaffLeaveApplyList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
       let ddd = null

        const response = await ViService.viXGet(
          `/home/getStaffLeaveApplyList?applyDate=${ddd}`,
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          
          this.staffApplyLeaveRequest = result
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    openActionPopUp(data,index){
      this.staffLeaveDetails = data
      this.staffLeaveDetails.from = this.staffLeaveDetails.from.substring(0, 10);
      this.staffLeaveDetails.to = this.staffLeaveDetails.to.substring(0, 10);

      this.$bvModal.show("viewActionPopUp");
    },
    closeActionPopUp(){
      this.staffLeaveDetails = {}
      this.$bvModal.hide("viewActionPopUp");
    },
    async leaveAction(data,type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        data['actionType'] = type
        const response = await ViService.viXPost(
          "/home/leave/staff/approved",
          data,
          userData.token
        );

        // const response = await ViService.viXGet(
        //   "/home/leave/staff/approved",
        //   userData.token
        // );
        if (response.isSuccess) {
          this.closeActionPopUp()
          this.$toasted.success(response.message);
          this.getStaffLeaveApplyList()
         
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getStudentLeaveApplyList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/getStudentLeaveApplyList?applyDate=null",
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          this.studentApplyLeaveRequest = result

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    openStudentActionPopUp(data,index){
      this.studentLeaveDetails = data
      this.studentLeaveDetails.from = this.studentLeaveDetails.from.substring(0, 10);
      this.studentLeaveDetails.to = this.studentLeaveDetails.to.substring(0, 10);

      this.$bvModal.show("viewStudentActionPopUp");
    },
    closeStudentActionPopUp(){
      this.studentLeaveDetails = {}
      this.$bvModal.hide("viewStudentActionPopUp");
    },
    async studentLeaveAction(data,type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        data['actionType'] = type
        const response = await ViService.viXPost(
          "/home/leave/student/approved",
          data,
          userData.token
        );

       
        if (response.isSuccess) {
          this.closeStudentActionPopUp()
          this.$toasted.success(response.message);
          this.getStudentLeaveApplyList()
        
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    }
    
  },
    components: {
      ViInboxHomeRequest: () => import("./ViInboxHome"),
    },
  };
  </script>
  
  